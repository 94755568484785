<template>
  <section>
    <h1>RUPES</h1>

    <v-row class="mt-8">
        <v-col cols="12" sm="6" md="4">
            <v-card>
                <div class="d-flex flex-no-wrap justify-space-between">
                    <div class="content-card">
                        <v-card-title class="text-h5">Solicitudes de registro</v-card-title>
                        <v-card-actions>
                            <v-btn
                                block
                                rounded
                                color="secondary"
                                :to="{ name: 'aprobacion-proveedor' }"
                            >
                                Ver listado
                            </v-btn>
                        </v-card-actions>
                    </div>

                    <div>
                        <v-avatar
                            class="ma-3"
                            size="125"
                            tile
                        >
                            <v-icon size="50">mdi-note-alert</v-icon>
                        </v-avatar>
                    </div>
                </div>
            </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
            <v-card>
                <div class="d-flex flex-no-wrap justify-space-between">
                    <div class="content-card">
                        <v-card-title class="text-h5">Proveedores registrados</v-card-title>
                        <v-card-actions>
                            <v-btn
                                block
                                rounded
                                color="secondary"
                                :to="{ name: 'list-proveedores' }"
                            >
                                Ver listado
                            </v-btn>
                        </v-card-actions>
                    </div>

                    <div>
                        <v-avatar
                            class="ma-3"
                            size="125"
                            tile
                        >
                            <v-icon size="50">mdi-account-group</v-icon>
                        </v-avatar>
                    </div>
                </div>
            </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4">
            <v-card>
                <div class="d-flex flex-no-wrap justify-space-between">
                    <div class="content-card">
                        <v-card-title class="text-h5">Sanciones</v-card-title>
                        <v-card-actions>
                            <v-btn
                                block
                                rounded
                                color="secondary"
                                :to="{ name: 'listado-proveedores-sancionados' }"
                            >
                                Ver listado
                            </v-btn>
                        </v-card-actions>
                    </div>

                    <div>
                        <v-avatar
                            class="ma-3"
                            size="125"
                            tile
                        >
                            <v-icon size="50">mdi-account-cancel</v-icon>
                        </v-avatar>
                    </div>
                </div>
            </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="haveRoles(['ROLE_DIRECCION_COMPRAS', 'ROLE_TECNICO_DIRECCION_COMPRAS'])">
            <v-card>
                <div class="d-flex flex-no-wrap justify-space-between">
                    <div class="content-card">
                        <v-card-title class="text-h5">Sanciones a proveedores no registrados</v-card-title>
                        <v-card-actions>
                            <v-btn
                                block
                                rounded
                                color="secondary"
                                :to="{ name: 'sanciones-proveedores-no-registrados' }"
                            >
                                Ver listado
                            </v-btn>
                        </v-card-actions>
                    </div>

                    <div>
                        <v-avatar
                            class="ma-3"
                            size="125"
                            tile
                        >
                            <v-icon size="50">mdi-file-clock-outline</v-icon>
                        </v-avatar>
                    </div>
                </div>
            </v-card>
        </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
    name: "rupesView",
    components: {},
    data: () => ({

    }),
    methods:{},
    computed: {},
}
</script>

<style lang="scss" scoped>
.v-card__title {
    word-break: normal !important;
    min-height: 100px !important;
}
.content-card {
    min-width: 55%;
}
</style>